import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createHashRouter } from "react-router-dom";
import HomePage from './Pages/home_page';
import { AnimatePresence } from 'framer-motion';
import ErrorPage from './Pages/error';

const routes = createHashRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AnimatePresence>
      <RouterProvider router={routes} />
    </AnimatePresence>
  </React.StrictMode>
);

