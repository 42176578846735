import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
  return (
    <div className="error-page">
      <h1 className='error-title'>{error.status}</h1>
      <p className='error-text'>
        {/* Sorry, an unexpected error has occurred.  */}
        {error.statusText || error.message}
      </p>
      <i className='error-text'>
        {error.message || error.data}
        <br /><br />
        {error.error.stack || error.message}
      </i>
      <Link to={`/home`} className='primary-button'>Go to Home <FeatherIcon icon='arrow-up-right' size={22} /></Link>
    </div>
  );
}
