import React from 'react';
import { motion } from "framer-motion";
import FeatherIcon from 'feather-icons-react';

const CardComponent = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, transform: `translateY(240px)` }}
            whileInView={{ opacity: 1, transform: `translateY(0px)` }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            {...props}
            className='card column32'
        >
            <div className='column16'>
                <h1 className='title-large'>{props.index}</h1>
                <h1 className='sub-title'>{props.title}</h1>
            </div>

            <p className='primary-text'>{props.description}</p>

            <a href='#!' className="secondary-button">
                Get a Quote
                <FeatherIcon icon='arrow-up-right' size={22} />
            </a>
        </motion.div>
    );
};
export default CardComponent;