import React from 'react';
import FeatherIcon from 'feather-icons-react';
import HeaderComponent from '../Components/header.component';
import AnimatedComponent from '../Components/animated.component';
import ImageLodercomponent from '../Components/image.component';
import CardComponent from '../Components/card.component';
import { AnimatePresence, motion } from 'framer-motion';

const HomePage = () => {
    const formRef = React.useRef();
    const [user_email, setUserEmail] = React.useState('');
    const [user_name, setUserName] = React.useState('Submit');
    const [message, setMessage] = React.useState('Submit');
    const [isSubmitted, SetIsSubmitted] = React.useState(false);
    const [isError, SetIsError] = React.useState(true);
    const [buttonText, setButtonText] = React.useState('Submit');
    const [selectedId, setSelectedId] = React.useState(null);
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [activeTestimonial, setActiveTestimonial] = React.useState(0);

    const services = [
        {
            title: "Web Development",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, voluptatum, quae officiis totam architecto facilis quam vel eligendi nesciunt assumenda voluptas dolorum praesentium consequuntur quas a enim deserunt expedita nam!",
        },
        {
            title: "App Development",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, voluptatum, quae officiis totam architecto facilis quam vel eligendi nesciunt assumenda voluptas dolorum praesentium consequuntur quas a enim deserunt expedita nam!",
        },
        {
            title: "Brand",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, voluptatum, quae officiis totam architecto facilis quam vel eligendi nesciunt assumenda voluptas dolorum praesentium consequuntur quas a enim deserunt expedita nam!",
        },
    ];

    const projects = [
        {
            image: "./Assets/Images/Zivakwawakabva.png",
            tag: "Ui/Ux App Design",
            title: "Zivakwawakabva",
            url: "https://zivakwawakabva.com/",
            description: "Developing a successful app requires a methodical and strategic approach. The first step in the app development process is identifying the target audience and understanding their needs and preferences. This crucial step lays the foundation for all subsequent decisions throughout the development process."
        },
        {
            image: "./Assets/Images/Alimah International.png",
            tag: "Web Design",
            title: "Alimah International",
            url: "https://www.alimahinternational.org/",
            description: "Developing a successful app requires a methodical and strategic approach. The first step in the app development process is identifying the target audience and understanding their needs and preferences. This crucial step lays the foundation for all subsequent decisions throughout the development process."
        },
        {
            image: "./Assets/Images/Kadosh Glory.png",
            tag: "Web Design",
            title: "Kadosh Voice Of Glory International",
            url: "https://www.kadoshglory.org/",
            description: "Kadosh Voice Of Glory Is a that promote schurch and community involvement in working with these institutions of OVC care; at risk OVC into family and community support systems; and children living in streets in our journey of ..."
        }
    ];

    const testimonials = [
        {
            image: "./Assets/Images/Zivakwawakabva.png",
            logo: "./Assets/Images/Logo/zk-logo.png",
            tag: "Ui/Ux App Design",
            title: "Zivakwawakabva",
            url: "https://zivakwawakabva.com/",
            message: "Developing a successful app requires a methodical and strategic approach. The first step in the app development process is identifying the target audience and understanding their needs and preferences. This crucial step lays the foundation for all subsequent decisions throughout the development process."
        },
        {
            image: "./Assets/Images/Alimah International.png",
            logo: "./Assets/Images/Logo/Alimah Internatnional Logo.webp",
            tag: "Web Design",
            title: "Alimah International",
            url: "https://www.alimahinternational.org/",
            message: "Developing a successful app requires a methodical and strategic approach. The first step in the app development process is identifying the target audience and understanding their needs and preferences. This crucial step lays the foundation for all subsequent decisions throughout the development process."
        },
        {
            image: "./Assets/Images/Kadosh Glory.png",
            logo: "./Assets/Images/Logo/KadoshGlory Logo.webp",
            tag: "Web Design",
            title: "Kadosh Voice Of Glory International",
            url: "https://www.kadoshglory.org/",
            message: "Kadosh Voice Of Glory Is a that promote schurch and community involvement in working with these institutions of OVC care; at risk OVC into family and community support systems; and children living in streets in our journey of ..."
        }
    ];

    const figmaDesigns = [
        "./Assets/Images/Faq.png",
        "./Assets/Images/Home.png",
        "./Assets/Images/Sign Up.png",
        // "./Assets/Images/Faq.png",
    ];


    const handleSubmit = async (ev) => {
        ev.preventDefault();

        setButtonText("Sending...");

        // const client = new SMTPClient({
        //     host: "smtp.hostinger.com",
        //     port: 465,
        //     user: "info@allenchinodakufa.com",
        //     password: "G7b6208{",
        // });

        // const client_message = new Message({
        //     to: 'info@allenchinodakufa.com',
        //     from: user_email,
        //     Subject: `You have a new form submission from ${user_email}`,
        //     text: `Site
        //         Allen Chinodakufa Site

        //         Submitted content

        //         Name: ${user_name}

        //         Email: ${user_email}

        //         Message: ${message}`,
        // });


        // client.send(client_message, (err, msg) => {
        //     console.log(err || msg);

        //     if (msg) {
        //         SetIsSubmitted(true);
        //         SetIsError(true);
        //     }
        //     if (err) {
        //         SetIsSubmitted(true);
        //         SetIsError(true);
        //     }
        // });


        setButtonText("Submit");
        return false;
    }


    const handlePopup = (id, ev, prj) => {
        // ev.preventDefault();

        setSelectedId(id);
        setSelectedProject(prj);
    }
    return (
        <>
            {/*  Navigation  */}
            <HeaderComponent />
            {/* Hero */}
            <AnimatedComponent className="hero">
                <div className="hero-desc column32">
                    <h1 className="title-large">
                        I am <span className="tag primary-bg">Ui/Ux</span>
                        <br />
                        Allen Chinodakufa
                    </h1>
                    <p className="primary-text">
                        Designing websites and apps for 7 years.
                    </p>
                    <div className="flex32">
                        <a href='#!' className="primary-button">
                            Hire Me
                            <FeatherIcon icon='arrow-right' size={22} />
                        </a>
                        <a href='#!' className="secondary-button">
                            Projects
                            <FeatherIcon icon='arrow-up-right' size={22} />
                        </a>
                    </div>
                </div>
                <div className="hero-image">
                    <div className="hero-aside">
                        <div className="image-container">
                            <ImageLodercomponent
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9693f768fe51466d73f8dac26b1dcb0c32528213089a0252e8fa949140f9d98a?apiKey=92b0dde60dcb4caf8a085ac59d30d768&width=2000"
                                className="image-fluid"
                            />
                        </div>
                    </div>
                </div>
            </AnimatedComponent>
            {/* Favorite Designs */}
            <AnimatedComponent transition={{ delay: 0.25 }} className="section secondary-bg">
                <div className='container'>
                    <div className='column32 stretch'>
                        <div className='column16'>
                            <h4 className='sub-title white'>Favorite Designs</h4>
                            <h1 className='title-text white'>Some of my most favorite work</h1>
                            <hr className='white' />
                        </div>
                        <div className='flex32'>
                            <div className='col6'>
                                <div className="image-container">
                                    <ImageLodercomponent
                                        loading="lazy"
                                        src="./Assets/Images/Food.png"
                                        className="image-fluid"
                                    />
                                </div>
                            </div>

                            <div className='col6 grid32'>
                                {
                                    figmaDesigns.map((image, i) =>
                                        <div className='col12'>
                                            <div className="image-container">
                                                <ImageLodercomponent
                                                    loading="lazy"
                                                    src={image}
                                                    className="image-fluid"
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Services Designs */}
            <AnimatedComponent transition={{ delay: 0.25 }} className="section">
                <div className='container'>
                    <div className='column64 stretch'>
                        <div className='column16'>
                            <h4 className='sub-title'>Services</h4>
                            <h1 className='title-text'>See What I Offer</h1>
                            <hr />
                        </div>

                        <div className='flex32'>
                            {
                                services.map((service, i) =>
                                    <CardComponent title={service.title} index={i + 1} description={service.description} transition={{ type: "spring", stiffness: 100, delay: 0.75 - (i / 5), }} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Projects Designs */}
            <AnimatedComponent transition={{ delay: 0.25 }} className="section secondary-bg">
                <div className='container'>
                    <div className='column64 stretch'>
                        <div className='column16'>
                            <h4 className='sub-title white'>Projects</h4>
                            <h1 className='title-text white'>What I have been working on</h1>
                            <hr className='white' />
                        </div>

                        <div className='column stretch'>
                            {projects.map((project, index) => (
                                <AnimatedComponent transition={{ delay: 0.25 }} className={`${index === 1 ? 'flex flex-reverse' : 'flex'}`} >

                                    <div className='col6'>
                                        <AnimatedComponent transition={{ type: "spring", stiffness: 100, delay: 0.75 - (index / 5), }} className='image-container'>
                                            <ImageLodercomponent
                                                src={project.image}
                                                className='project-image'
                                                loading="lazy"
                                                layoutId={project.image}
                                                onClick={(ev) => handlePopup(project.image, ev, project)}
                                            />
                                        </AnimatedComponent>
                                    </div>

                                    <div className='col6'>
                                        <div className='container column32'>
                                            <span className='tag'>{project.tag}</span>
                                            <div className='column16'>
                                                <h3 className='sub-title white'>{project.title}</h3>
                                                <p className='primary-text white clamp-text'>{project.description}</p>
                                            </div>
                                            <a href={project.url} className="flex16 white" target='_blank' rel="noreferrer">
                                                Visit Website
                                                <FeatherIcon icon='arrow-up-right' size={22} />
                                            </a>
                                        </div>
                                    </div>

                                </AnimatedComponent>
                            ))}
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Gigs */}
            <AnimatedComponent transition={{ delay: 0.25 }} className="section">
                <div className='container'>
                    <div className='box with-svg'>
                        <div className='column32'>
                            <div className='column16'>
                                <h4 className='sub-title'>Hire Me</h4>
                                <h1 className='title-text'>Choose a Gig</h1>
                                <hr />
                            </div>

                            <p className='primary-text'>
                                As an experienced app developer, I offer a wide range of services that set me apart from the competition.
                                My expertise in coding, design, and user experience allows me to create high-quality apps that are both
                                functional and visually appealing.
                            </p>

                            <a href='#!' className="primary-button">
                                See my gig
                                <FeatherIcon icon='arrow-up-right' size={22} />
                            </a>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Testimonial Designs */}
            <AnimatedComponent transition={{ delay: 0.25 }} className="section secondary-bg">
                <div className='container'>
                    <div className='column64 stretch'>
                        <div className='column16'>
                            <h4 className='sub-title white'>Testimonials</h4>
                            <h1 className='title-text white'>Don't just take my word</h1>
                            <hr className='white' />
                        </div>

                        <div className='flex32'>
                            <div className='col6'>
                                <div className='image-container'>
                                    <ImageLodercomponent src={testimonials[activeTestimonial].image} className='image-fluid' />
                                </div>
                            </div>

                            <div className='col6'>
                                <div className='column16'>
                                    {testimonials.map((testimonial, index) => (
                                        <AnimatedComponent transition={{ type: "spring", stiffness: 100, delay: 0.75 - (index / 10), }} className={`${activeTestimonial === index ? 'box active' : 'box with-no-border'}`} onClick={(ev) => {
                                            ev.preventDefault();
                                            setActiveTestimonial(index);
                                        }}>
                                            <div className='column32'>
                                                <span className='flex4'>
                                                    <FeatherIcon icon='star' size={18} />
                                                    <FeatherIcon icon='star' size={18} />
                                                    <FeatherIcon icon='star' size={18} />
                                                    <FeatherIcon icon='star' size={18} />
                                                    <FeatherIcon icon='star' size={18} />
                                                </span>

                                                <p className='primary-text clamp-text'>{testimonial.message}</p>

                                                <div className='flex16'>
                                                    <div className='image-container-icon col3'>
                                                        <ImageLodercomponent src={testimonial.logo} className='image-fillheight' />
                                                    </div>
                                                    <div className='column4'>
                                                        <h1 className='sub-title'>{testimonial.title}</h1>
                                                        <span className='tag primary-bg'>{testimonial.tag}</span>
                                                    </div>
                                                </div>
                                                <a href={testimonial.url} className="fricon-button" target='_blank' rel="noreferrer">
                                                    <FeatherIcon icon='arrow-up-right' size={22} />
                                                </a>
                                            </div>
                                        </AnimatedComponent>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            {/* Contact Me*/}
            <AnimatedComponent transition={{ delay: 0.25 }} className="section">
                <div className='container col12'>
                    <div className='box flex32 col12'>
                        <div className='col5'>
                            <div className='column-auto column32'>
                                <div className='column16'>
                                    <h4 className='sub-title'>Contact Us</h4>
                                    <h1 className='title-text'>Get In Touch</h1>
                                    <hr />
                                </div>

                                <div className='flex16'>
                                    <a href='#!' className='icon-button'><FeatherIcon icon='phone' size={22} /></a>
                                    <a href='#!' className='icon-button'><FeatherIcon icon='linkedin' size={22} /></a>
                                    <a href='#!' className='icon-button'><FeatherIcon icon='instagram' size={22} /></a>
                                </div>
                            </div>
                        </div>
                        <div className='col7 stretch'>

                            {((isSubmitted === false && isError === true) ||
                                (isSubmitted === true && isError === true)) && (
                                    <form className='col12 column32 stretch' ref={formRef} onSubmit={handleSubmit}>
                                        <h2 className='sub-title'>Let's Connect</h2>
                                        <div className='column8'>
                                            <label className='primary-text'>Name:</label>
                                            <input className='form-control' placeholder='Enter your full name...' name='user_name' required />
                                        </div>
                                        <div className='column8'>
                                            <label className='primary-text'>Email:</label>
                                            <input className='form-control' placeholder='Enter your email...' name='user_email' required />
                                        </div>
                                        <div className='column8'>
                                            <label className='primary-text'>Subject:</label>
                                            <input className='form-control' placeholder='Enter your subject...' required />
                                        </div>
                                        <div className='column8'>
                                            <label className='primary-text'>Message:</label>
                                            <textarea className='form-control' placeholder='Enter your message...' name='message' required />
                                        </div>
                                        <div className='flex16'>
                                            <button type='submit' className="primary-button">
                                                {buttonText}
                                                <FeatherIcon icon='arrow-right' size={22} />
                                            </button>
                                            <a href='mailto:info@allenchinodakufa.com' className="secondary-button" target='_blank' rel="noreferrer">
                                                Lets Talk
                                                <FeatherIcon icon='arrow-right' size={22} />
                                            </a>
                                        </div>
                                    </form>
                                )}

                            <AnimatePresence initial={false}>
                                {isSubmitted === true && isError === false && (
                                    <motion.div
                                        className="success-message"
                                        initial={{ transform: `translateY(240px)` }}
                                        animate={{ transform: `translateY(0px)` }}
                                        transition={{ duration: 0.5, ease: "linear" }}
                                    >
                                        <p className="primary-text">
                                            Thank you! Your submission has been received!
                                        </p>
                                    </motion.div>
                                )}

                                {isSubmitted === true && isError === true && (
                                    <motion.div
                                        className="error-message"
                                        initial={{ transform: `translateY(240px)` }}
                                        animate={{ transform: `translateY(0px)` }}
                                        transition={{ duration: 0.5, ease: "linear" }}
                                    >
                                        <p className="primary-text">
                                            Oops! Something went wrong while submitting the
                                            form.
                                        </p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </AnimatedComponent>

            <AnimatePresence>
                {selectedId && (
                    <AnimatedComponent transition={{ delay: 0.25 }} className='popup'>
                        <div className='box'>
                            <div className='flex32'>
                                <motion.div
                                    animate={"open"}
                                    variants={{
                                        close: { opacity: 0, transform: `translateY(240px)` },
                                        open: { opacity: 1, transform: `translateY(0px)` }
                                    }}
                                    transition={{ duration: 1, ease: "easeInOut", type: "spring", stiffness: 100 }}
                                    className='image-container col5'
                                    onClick={(ev) => handlePopup(null, ev, null)}>
                                    <img
                                        src={selectedProject.image}
                                        className='project-image'
                                        loading="lazy"
                                        alt={selectedProject.title}
                                    />
                                </motion.div>

                                <div className='col7 column32'>
                                    <div className='column'>
                                        <h3 className='sub-title'>{selectedProject.title}</h3>
                                        <span className='tag primary-bg'>{selectedProject.tag}</span>
                                    </div>
                                    <p className='primary-text'>{selectedProject.description}</p>

                                    <div className='flex'>
                                        <a href={selectedProject.url} className="primary-button" target='_blank' rel="noreferrer">
                                            Visit Website
                                            <FeatherIcon icon='arrow-up-right' size={22} />
                                        </a>
                                        <button onClick={(ev) => handlePopup(null, ev, null)} className="secondary-button">
                                            Close
                                            <FeatherIcon icon='x' size={22} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AnimatedComponent>
                )}
            </AnimatePresence >


        </>
    )

}

export default HomePage;