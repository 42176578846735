import React from 'react';
import FeatherIcon from 'feather-icons-react';

export default function HeaderComponent() {
    return (
        <header className="navbar">
            <div className="navbar-brand">Allen.</div>
            <nav className="menu">
                <a href='#!' className="nav-link">Home</a>
                <a href='#!' className="nav-link">Services</a>
                <a href='#!' className="nav-link">Projects</a>
                <a href='#!' className="nav-link">Testominals</a>
            </nav>
            <a href='mailto:info@allenchinodakufa.com' className="primary-button" target='_blank' rel="noreferrer">
                Let's Chat
                <FeatherIcon icon="arrow-right" size={22} />
            </a>
        </header>
    )
}
