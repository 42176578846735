import React from 'react';
import { motion } from "framer-motion";

const AnimatedComponent = (props) => {
    return (
        <motion.div
            initial={{ opacity: 0, transform: `translateY(240px)` }}
            whileInView={{ opacity: 1, transform: `translateY(0px)` }}
            viewport={{ once: true }}
            transition={{ duration: 1, ease: "easeInOut" }}
            {...props}
        >
            {props.children}
        </motion.div>
    );
};
export default AnimatedComponent;